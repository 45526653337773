<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="7">
        <LoginBox />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="7">
        <v-card>
          <v-card-text>
            <ul>
              <li>
                본 시스템을 사용할 경우 Informatica GCS policy에 동의하는 것으로
                간주합니다.
              </li>
              <li>
                해당 시스템으로 SR case를 등록할 때 입력하는 고객의 개인정보는
                Informatica 업무 활용 및 고객 지원 목적으로 내부적으로만
                활용되며, 관련 법규 및 회사의 개인정보 보호정책에 따라
                처리됩니다.
              </li>
              <li>
                SR case를 등록할 때 제공되는 모든 정보는 정확하고 최신 상태여야
                합니다. 부정확한 정보는 Support 지연의 원인이 될 수 있습니다.
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginBox from "@/components/Views/Login/LoginBox";
export default {
  components: {
    LoginBox,
  },
};
</script>
