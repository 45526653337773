<template>
  <v-card elevation="4">
    <v-container>
      <v-row justify="center" class="py-3">
        <v-img
          alt="informatica-logo"
          class="shrink mr-2"
          contain
          src="~@/assets/logo/INFA_ISK_LOGO.svg"
          transition="scale-transition"
          width="80%"
        />
      </v-row>
      <v-row>
        <v-col>
          <h1 class="display-1 text-center">CUSTOMER SUPPORT SYSTEM</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="9">
          <v-container class="py-0">
            <v-row>
              <v-col cols="12" class="pb-0 px-0">
                <v-text-field
                  prepend-icon="mdi-account"
                  label="ACCOUNT ID"
                  outlined
                  dense
                  hide-details
                  v-model="user_id"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pt-1 px-0">
                <v-text-field
                  prepend-icon="mdi-form-textbox-password"
                  label="PASSWORD"
                  pass
                  outlined
                  dense
                  type="password"
                  hide-details
                  v-model="user_pw"
                  @keyup.enter="doLogin"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" sm="3" class="pt-0 pt-sm-3">
          <v-btn
            block
            class="py-sm-10 title"
            color="#FF7D00"
            dark
            @click="doLogin"
          >
            ACCESS
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="caption text-center my-1">
            허가되지 않은 접속을 시도하는 경우 법령에 따라 불이익이 있을 수
            있습니다.
          </p>
          <p class="caption text-center my-1">
            계정의 생성/분실은 담당자에게 문의하시기 바랍니다.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { doLogin } from "@/api/Auth";
export default {
  methods: {
    async doLogin() {
      if (this.user_id == null || this.user_id == "") {
        return alert("아이디를 입력하세요.");
      }
      if (this.user_pw == null || this.user_pw == "") {
        return alert("암호를 입력하세요.");
      }
      const data = await doLogin(this.user_id, this.user_pw);
      if (!data.success || !data.login_status) {
        return alert(data.message);
      }
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("refToken", data.data.refToken);
      localStorage.setItem("userData", JSON.stringify(data.data.userData));
      this.$store.commit("userInfo/updateInfo");

      const LoginRedirect = this.$store.getters["userInfo/loginRedirect"];
      console.log(LoginRedirect);
      if (LoginRedirect == null) this.$router.push("/");
      else {
        this.$store.commit("userInfo/setLoginRedirect", null);
        this.$router.push(LoginRedirect.path);
      }
    },
  },
  data() {
    return {
      user_id: null,
      user_pw: null,
    };
  },
};
</script>
